const jamstackStyle = {
  container: {
    backgroundColor: "coverBackground",
    pb: 4,
  },
  containerBox: {
    variant: "variants.container",
  },
  containerSection: {
    pb: 5,
  },
  navBg: {
    bg: "#eeeeee",
    display: ["none", "none", "none", "block"],
    fontSize: 1,
  },
  navContainer: {
    variant: "variants.container",
    mx: "auto",
    my: 0,
    py: 0,
    px: [3, 4, 3, 5],
  },
  navDropdown: {
    position: "relative",
    display: "inline-block",
    lineHeight: "0",
    zIndex: "999",
    h3: {
      display: "inline-block",
      color: "#777",
      fontWeight: "bold",
      fontSize: 1,
      textTransform: "inherit",
      py: 4,
      mr: 4,
      mb: 0,
      cursor: "default",
      position: "relative",
      "&:before": {
        content: '""',
        display: "none",
        position: "absolute",
        bottom: 0,
        transform: "translateX(-50%)",
        border: "6px solid transparent",
        borderBottomColor: "rgb(121, 156, 45)",
        left: "50%",
      },
    },
    "&:first-of-type": {
      ml: 0,
    },
    "> ul": {
      display: "none",
      position: "absolute",
      width: "240px",
      bg: "#fff",
      borderRadius: "0 0 12px 12px",
      m: 0,
      padding: 3,
      listStyle: "none",
      color: "#a29e9e",
      lineHeight: "32px",
      boxShadow: "0 10px 10px rgba(0,0,0,0.15)",
    },
    "> ul a": {
      color: "#777",
      "&:hover": {
        color: "rgb(121, 156, 45)",
      },
      "&[aria-current='page']": {
        color: "rgb(121, 156, 45)",
        fontWeight: "bold",
      },
    },
    "&:hover > ul": {
      display: "block",
      borderTop: "3px solid rgb(121, 156, 45)",
    },
    "&:hover > h3": {
      color: "#000",
      "&:before": {
        display: "inline-block",
      },
    },
  },
}
export default jamstackStyle
