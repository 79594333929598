const productsListStyles = {
  productCard: {
    display: "block",
    p: 0,
    pb: 0,
    borderBottom: "1px solid #eee",
  },
  productImage: {
    borderRadius: "8px 8px 0 0",
  },
  productBody: {
    p: 3,
    lineHeight: "normal",
  },
  productHeading: {
    mb: 0,
  },
  productLink: {
    fontSize: 3,
    color: "#2d54c9",
  },
  productSpan: {
    fontSize: 1,
    opacity: 0.8,
    "a":{
      color: "#565656",
      "&:hover":{
        color: "#709520"
      }
    }
  },
  productParagraph: {
    fontSize: 2,
    color: "#565656",
    lineHeight: 1.5,
    minHeight: 96,
  },
  productPrice: {
    fontSize: 3,
    fontWeight: 600,
    pb: 2,
  },
}
export default productsListStyles
