/** @jsx jsx */
import { Card, jsx } from "theme-ui"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import productsListStyles from "../components/products-list.styles"

const ProductCard = ({ data }) => {
  const product = data
  const pathLink = product.path + "/"
  return (
    <Card key={product.id}>
      <Link to={pathLink} sx={productsListStyles.productCard}>
        {product.featuredImage && (
          <GatsbyImage
            image={product.featuredImage.childImageSharp.gatsbyImageData}
            sx={productsListStyles.productImage}
          />
        )}
      </Link>
      <div sx={productsListStyles.productBody}>
        <h2 sx={productsListStyles.productHeading}>
          <Link to={pathLink} sx={productsListStyles.productLink}>
            {product.title}
          </Link>
        </h2>
        {product.author.title && (
          <span sx={productsListStyles.productSpan}>By{" "}
          <Link to={"/author/" + product.author.slug + "/"}>
            <strong>{product.author.title}</strong>
          </Link>
          </span>
        )}
        <p sx={productsListStyles.productParagraph}>
          {product.meta_description}
        </p>
        <div sx={productsListStyles.productPrice}>
          {product.productType[0].price === 0 ? (
            <span>Free</span>
          ) : (
            <span>
              $ {product.productType[0].price}
              <small>.00</small>
            </span>
          )}
        </div>
      </div>
    </Card>
  )
}

export default ProductCard
