/** @jsx jsx */
import { jsx } from "theme-ui"

export default function HeaderCard({ children }) {
  return <header sx={HeaderStyles}>{children}</header>
}

const HeaderStyles = {
  mb: 5,
  h1: {
    fontWeight: 900,
    fontSize: [4, 5],
    mb: 3,
  },
  p: {
    mt: 0,
    mb: 4,
    color: "rgba(0,0,0,0.8)",
  },
}
